// =============================================================================
// app - javascript - entrypoints - sign in
// =============================================================================
import * as Rails from '@rails/ujs';

import * as Turbo from '@hotwired/turbo';
import '@app/controllers/index'; // Stimulus

// import * as UIkit from 'uikit';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// eslint-disable-next-line no-underscore-dangle
if (!window._rails_loaded) {
  Rails.start();
}
Turbo.session.drive = true;
UIkit.use(Icons);

window.UIkit = UIkit;
